<template>
  <div class="roof bg-white FoundersGrotesk">
    <section class="pd-mobile container-xl">
      <div v-for="(rowData, index) in dataRows" :key="index" class="mb-5 h-100">
        <b-row class="">
          <b-col class="m-0 p-0" cols="12" md="6">
            <div class="lg-text text-uppercase">THE ROOF</div>
          </b-col>
          <b-col cols="12" md="6" class="m-0 p-0">
            <div
              v-html="formatText(rowData.text)"
              class="md-text fade-in-up-element pb-4"
            ></div>
            <a target="_blank" :href="rowData.link" class="link-text">{{
              rowData.linkText
            }}</a>
          </b-col>
        </b-row>
      </div>
    </section>
    <div class="w-100 d-flex align-items-center justify-content-center">
      <div class="outer-container">
        <VueSlickCarousel v-bind="settings">
          <div
            v-for="(image, index) in slickImages"
            :key="index"
            class="slider-container"
          >
            <div>
              <div class="image-container">
                <img
                  :src="image.url"
                  :alt="image.title"
                  class="d-block mx-auto img-fluid"
                />
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  data() {
    return {
      settings: {
        arrows: true,
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
      dataRows: [
        {
          text: "When you say Ode’s rooftop - I say…nothing, I’m already on my way to the roof and have left the conversation. Stretch here in the mornings or spend an afternoon here relaxing all under the Toronto sun. If you are interested in booking the rooftop for an event, get at us!",
          link: "/roof",
          linkText: "BOOK THE ROOF >",
        },
      ],
      slickImages: [
        {
          url: "/img/rooms/Deck-1.jpg",
        },
        {
          url: "/img/rooms/Deck-2.jpg",
        },
        {
          url: "/img/rooms/Deck-3.jpg",
        },
        {
          url: "/img/rooms/DeckGlass2.jpg",
        },
        {
          url: "/img/rooms/Deck-4.jpg",
        },
        {
          url: "/img/rooms/Deck-5.jpg",
        },
      ],
    };
  },
  components: { VueSlickCarousel },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {}, 500);
  },
  methods: {
    formatText(text) {
      return text.replace(/\n/g, "<br>");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.roof {
  padding: 50px 0px 100px 0px;
  @media screen and (max-width: 1176px) {
    padding: 40px 0px 100px 0px;
  }
  .pd-mobile {
    @media screen and (max-width: 1176px) {
      padding: 0px 60px 0px 60px;
    }
  }
  .link-text {
    letter-spacing: 1px;
  }
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $m-black;
    text-decoration: none;
  }
  .slider-container {
    position: relative;
  }
  .slider-container img {
    max-height: 500px;
    border: 2px solid $m-black;
    @media screen and (max-width: 600px) {
      border: none;
    }
  }

  .outer-container {
    width: 60%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  img {
    width: 100%;
  }

  .image-container {
    position: relative;
    text-align: center;
  }
}
</style>

<style lang="scss">
@import "./assets/styles/variables";
.roof {
  .outer-container {
    .slick-prev.slick-arrow {
      width: 50px;
      height: 50px;
    }
    .slick-prev:before {
      content: "";
      display: inline-block;
      width: 55px;
      height: 55px;
      background-image: url("/img/back.svg");
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
    .slick-prev {
      position: absolute;
      left: -70px;
    }
    .slick-next {
      position: absolute;
      right: -35px;
    }
    .slick-next:before {
      content: "";
      display: inline-block;
      width: 55px;
      height: 55px;
      background-image: url("/img/forward.svg");
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }
}
</style>
